
@import url('https://fonts.googleapis.com/css2?family=Silkscreen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Silkscreen&display=swap');


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.topNavbar{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 25px;
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  

}
.topNavbar-links{
  width: max-content;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 40px;
  
  
}
.topNavbar-links div{
  position: relative;
  margin-right: 40px;
  cursor: pointer;
}
.topNavbar-links span{
  position: relative;
  margin-left: 9px;
  text-decoration: none;
  color: #58585B;
  font-size: 0.85em;
  /* cursor: pointer; */
  font-weight: 500;
}
#signoutBtn{
  color:black;
  font-weight: 500;
  cursor: pointer;
}
.register , .signin{
  cursor: pointer;
}


.appHeader{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-image: linear-gradient( 68.4deg,  rgba(248,182,204,1) 0.5%, rgba(192,198,230,1) 49%, rgba(225,246,240,1) 99.8% );
}

.projectTitle{
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;

}
#brandName{
  color: rgb(36, 34, 34);
  font-family: 'Silkscreen', cursive;
}
#brandSubName{
  font-size: 30px;
  font-weight: 500;
  font-family: 'Caveat', cursive;
  color: white;
}
.selectLanguage{
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  border: 2px solid #d3dce6;

  background: #f5f5f5;
  padding: 8px 10px;
  min-width: 12vw;
  background: rgba(255, 255, 255, 0.14);
border-radius: 6px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.3px);
-webkit-backdrop-filter: blur(4.3px);
border: 1px solid rgba(255, 255, 255, 0.6);
}
.selectLanguage label{
  font-weight: 500;

}
.selectLanguage select{
  width: 12vh;
  outline:none ;
  border:1px solid #d3dce6;
  border-radius: 2px;
  background: none;
}
.codeArea{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* flex-direction: column; */
  max-height: 83.9vh;
  background-color: #f5f5f5;
  border: 2px solid #d3dce6;
  font-family: "euclid_circular";
  border-left: none;
  /* border-bottom: none; */
  /* flex-wrap: wrap; */
  /* overflow-y: hidden; */
}
.inputArea{
  position: relative;
  width: 50%;
  /* border-bottom: 2px solid #d3dce6; */
  height: 100%;



}
.inputArea textarea{
  border: none;
  overflow: auto;
  outline: none;
  background-color: #f5f5f5;;
font-family: euclid_circular;
font-weight: 500;
font-size: 16px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  padding-right: 10px;
}

.outputArea{
  font-family: euclid_circular;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  background-color: #f5f5f5;
  width: 50%;
  height: 100%;
  border-left: 2px solid #d3dce6;
  

}

.header-Area{
  position: relative;
  height: 8vh;
  width: auto;
  background-color: white;
  border-bottom: 2px solid #d3dce6;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header-Area-input .inputFileName{
  display: inline-flex;
  background-color: #f5f5f5;
  text-align: center;
  /* height: 5vh; */
  padding: 20px 24px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  border-right: 2px solid #d3dce6;
  

}
.header-Area-input-buttons{
  position: relative;
  padding: 12px 20px;
  display: flex;
}
.header-Area-input-buttons select{
  margin: 0px 4px;
}
.header-Area-input-buttons .runButton{
  display: flex;
  min-width: 47px;
  min-height: 12px;
  margin: 0px 4px;
  justify-content: center;
  font-family: "euclid_circular";
  justify-content: center;
  cursor: pointer;
  padding: 3px 10px;
  background: #0556f3;
  background-image: radial-gradient( circle farthest-corner at 1.3% 2.8%,  rgba(239,249,249,1) 0%, rgba(182,199,226,1) 100.2% );
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.3px);
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 20px;
  color: black;
  border: none;
  outline: none;
  border-radius: 2px;
  z-index: 109;
  font-weight: bold;
  align-items: center;
}

.outputTitle{
  padding-left: 20px;
  font-size: 18px;

}

.header-Area-output-buttons{
  position: relative;
    display: flex;
    align-items: center;
    width: auto;
    padding: 0px 10px;
    justify-content: space-around;
  
}
.codeDownloadImage{
  width: 25px;
  margin: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
}

.saveCodeImage{
  width: 20px;
  margin: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.saveCodeImage img{
  width: 20px;
  cursor: pointer;
}
.changeTheme{
  cursor: pointer;
}
.Tooltip{
  background-color: #f5f5f5;
  /* border: 2px solid #d3dce6; */
    position: absolute;
    border-radius: 5px;
    /* bottom: 130%; */
    padding: 5px 7px;
    font-size: 14px;
    visibility: hidden;
    /* transition: all .5s; */
    width: max-content;
}

.saveCodeImage:hover .Tooltip{
  position: absolute;
  z-index: 1;
  visibility: visible;
  transform: translatey(-30px);
  /* width: max-content; */
}
.codeDownloadImage:hover .Tooltip{
  visibility: visible;
  transform: translateY(-30px);
  width: max-content;
}

.outPutStatus{
  position: relative;
  font-size: 14px;
  border-radius: 2px;
  min-width: 90px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
#success{
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0px 5px;
  background-color: #198754;
}
#pending{
  color: black;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0px 5px;
  background-color: #ffc107;
}
#error{
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0px 5px;
  background-color: #dc3545;
}

.output-content{
  position: relative;
  margin-left: 10px;
  height: 75vh;
  overflow-y: scroll;
}
.output-jobId{
  position: relative;
  font-size: 15px;
  color: rgba(128, 128, 128, 0.482);

}
.output-submitedAt{
  position: relative;
  font-size: 15px;
  color: rgba(128, 128, 128, 0.482);
}
.output-code{
  font-family: "consolas";
  font-size: 16px;
}

.executionTime span{
  color: black;
  font-size: 15px;
  padding: 4px;
  border-radius: 2px;
  font-weight: bold;
  background-color: #f5f5f5;
  /* border: 2px solid #d3dce6; */

}


.registeUserContainer{
  position: absolute;
  background-color:#f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background-image: linear-gradient( 68.4deg,  rgba(248,182,204,1) 0.5%, rgba(192,198,230,1) 49%, rgba(225,246,240,1) 99.8% );
  

}
.registeUserForm{
  position: relative;
  width: 20%;
  height: auto;
  padding: 40px 70px;
  background-color: #d3dce6;
  border:2px solid #d3dce6 ;

  border-radius: 4px;
  flex-direction: column;
  background: rgba(255, 255, 255, 0);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.3px);
-webkit-backdrop-filter: blur(4.3px);
border: 1px solid rgba(255, 255, 255, 0.6);

}
.registeUserForm form{
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 10px 0px;
  justify-content: center;
  align-items: flex-start;
 

}
.registeUserForm h3{
  font-size: 1.5em;
  margin: 10px 0px;
  font-family: 'Silkscreen', cursive;
}

.registeUserForm form input{
  width: 100%;
  margin: 10px 0px;
  height: 5vh;
  padding: 0px 5px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #e8ebef3a;
}
.registeUserForm form input:focus{
  background-color: #e8ebefa5;
  border:1px solid #d3dce6 ;
  border-radius: 4px;
  letter-spacing: 1px;
  font-weight: 500;
  outline: none;
}
.registeUserForm form #registerSubmit{
  margin: 5px 0px;
  /* height: 50px; */
  width: 30%;
  padding: 10px 0px ;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  border: none;
  /* background: #0556f3; */
  background-image: radial-gradient( circle farthest-corner at 1.3% 2.8%,  rgba(239,249,249,1) 0%, rgba(182,199,226,1) 100.2% );
  /* border: 1px solid rgba(255, 255, 255, 0.6); */
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.3px);
  color: black;
  font-weight: bold;
  letter-spacing: 1px;
}
#closeRegister{
  position: absolute;
  z-index: 10000;
  top: 5%;
  right: 5%;
  width: 30px;
  cursor: pointer;
  
  margin: 10px;
}
.saveCodeContainer{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient( 68.4deg,  rgba(248,182,204,1) 0.5%, rgba(192,198,230,1) 49%, rgba(225,246,240,1) 99.8% );

}
.saveCodeMain{
  position: relative;
  width: 20%;
  height: auto;
  padding: 40px 70px;
  background-color: white;
  border:2px solid #d3dce6 ;
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
  flex-direction: column;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  
}

.saveCodeTitle{
  font-size: 1.5em;
  margin-bottom: 20px;
  font-family: 'Silkscreen', cursive;
}
.saveCodeMain form{

  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.saveCodeMain form label{
  margin-bottom: 5px;
  font-weight: 500;
}
.saveCodeMain form input{
  width: 100%;
  height: 4vh;
  padding: 0px 5px;
  background-color: #e8ebef3a;
  border:2px solid #d3dce6 ;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  
}
.saveCodeMain form input:focus{
  background-color: #e8ebefa5;
  border:2px solid #d3dce6 ;
  border-radius: 4px;
  letter-spacing: 1px;
  font-weight: 600;
  outline: none;
}
.saveCodeMain form button{
  margin: 15px 0px;
  /* height: 50px; */
  width: 30%;
  padding: 10px 0px ;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  border: none;
  background: #0556f3;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
}

.userLinks{
  position: absolute;
  top: 3vh;
  right: 5vh;
  border:2px solid #d3dce6 ;
  background-color: #f3f3f3;
  width: 25vh;
  height: auto;
  padding: 20px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 100000;

}
.userLinks a{
  
  width: 100%;
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin-bottom: 5px;
  border-radius: 2px;
}

.userFilesContainer{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient( 68.4deg,  rgba(248,182,204,1) 0.5%, rgba(192,198,230,1) 49%, rgba(225,246,240,1) 99.8% );

}
.userFilesMain{
  position: relative;
  width: 50%;
  height: auto;
  padding: 40px 70px;
  background-color: white;
  border:2px solid #d3dce6 ;
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
  flex-direction: column;
background: rgba(255, 255, 255, 0.14);
border-radius: 6px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.3px);
-webkit-backdrop-filter: blur(4.3px);
border: 1px solid rgba(255, 255, 255, 0.6);
}

.userFilesMainTitle{
  font-size: 1.5em;
  margin-bottom: 20px;
  font-family: 'Silkscreen', cursive;
}
.userFilesMain table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;


}
.userFileNameLink{
  cursor: pointer;
}

.userFilesMain table td, th {
  border: 1px solid rgba(255, 255, 255, 0.6);

  text-align: left;
  padding: 8px;
}
#tableHeaderRow{
  background-image: linear-gradient( 68.4deg,  rgba(248,182,204,0.8) 0.5%, rgba(192,198,230,1.14) 49%, rgba(225,246,240,1.14) 99.8% );
  /* From https://css.glass */
/* background: rgba(255, 255, 255, 0.14); */

box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(2.3px);
-webkit-backdrop-filter: blur(2.3px);
/* border: 1px solid rgba(255, 255, 255, 0.6); */
}
.userFilesMain table td{
  font-size: 14px;
}

.userFilesMain table tr {
  background-color: #f3f3f3;
}
.userFileActions{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 3px;

}
.userFileActions .Action-item{
  width: 14px;
  cursor: pointer;
}
.Action-item:hover .Tooltip{
  position: absolute;
  z-index: 1;
  visibility: visible;
  transform: translateY(-30px);
  /* transform: translateX(-10px); */
 
  /* width: max-content; */
}

.codeAreaCopyCodeBtn{
  width: 20px;
  margin: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.codeAreaCopyCodeBtn:hover .Tooltip{
  position: absolute;
  z-index: 1;
  visibility: visible;
  transform: translatey(-30px);
}
.codeAreaCopyCodeBtn img{
  width: 20px;

}

.shareCodeImage{
  width: 22px;
  margin: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.shareCodeImage:hover .Tooltip{
  position: absolute;
  z-index: 1;
  visibility: visible;
  transform: translatey(-30px);
}
.saveCodeImage img{
  width: 22px;
}



.saveCodeMain .shareCodeLink{

  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.saveCodeMain .shareCodeLink label{
  margin-right: 5px;
  font-weight: 500;
}
.saveCodeMain .shareCodeLink input{
  width: 100%;
  height: 4vh;
  padding: 0px 5px;
  background-color: #e8ebef3a;
  border:2px solid #d3dce6 ;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  
}
.saveCodeMain .shareCodeLink input:focus{
  background-color: #e8ebefa5;
  border:2px solid #d3dce6 ;
  border-radius: 4px;
  letter-spacing: 1px;
  font-weight: 600;
  outline: none;
}
.generateLinkExpiresContainer{
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  color: grey;
}